
<template>
      <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="主叫号码报备"  >
      <reportBest></reportBest>


      </a-tab-pane>
      <a-tab-pane key="2" tab="被叫号码报备">
     <reportBest2></reportBest2>

      </a-tab-pane>

    </a-tabs>
</template>

<script>
 import reportBest from "./reportBest.vue";
 import reportBest2 from "./reportBest2.vue";
export default {
  components: {
   reportBest,
   reportBest2
  },
  data() {
   return{}
  },


 

};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>
